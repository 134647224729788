import { render, staticRenderFns } from "./recollections_publc.vue?vue&type=template&id=255c3a1c&scoped=true&"
import script from "./recollections_publc.vue?vue&type=script&lang=js&"
export * from "./recollections_publc.vue?vue&type=script&lang=js&"
import style0 from "./recollections_publc.vue?vue&type=style&index=0&id=255c3a1c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "255c3a1c",
  null
  
)

export default component.exports