<template>
    <div>
        <div class="evaluate flex_style direction_column flex_nowrap justify_center">
            <div class="evaluateBox flex_style justify_end">
                <div class="evaluateImg_box">
                    <div class="swiper-container evaluateImg_con swiper-no-swiping">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide img-con-slide" v-for="(item,index) in stumessage" :key="index">
                                <img :src="item.image" alt/>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="evaluateCon flex_style direction_column flex_nowrap justify_start ">
                    <div class="con_title text_left" style="position:relative;">
                        <!--                        <span class="text_family">感言</span>-->
                        <h4 class="headline text_family text_color">就业学员感言<br/></h4>
                        <img src="../../assets/images/btzst.png" alt="">
                        <div class="arrowBox flex_style">
                            <img src="../../assets/images/jtle_03.png" alt="" class="evaluateCon-next">
                            <img src="../../assets/images/jtri_03.png" alt="" class="evaluateCon-prev">
                        </div>
                    </div>
                    <!-- 感言右面 文字内容 -->
                    <div class="swiper-containe evaluate_ringt">
                        <div class="swiper-wrapper" style="cursor:pointer">
                            <div class="swiper-slide evaluateRi-slide" v-for="(item,index) in stumessage" :key="index">
                                <div class="evaluateText flex_style direction_column flex_nowrap justify_around align_end">
                                    <div class="evaluateText_top">
                                        <p v-html="item.content">{{item.content}}</p>
                                    </div>
                                    <div class="evaluateText_bottom flex_style direction_row flex_nowrap justify_between">
                                        <div class="flex_style direction_column flex_nowrap justify_between">
                                            <p>{{item.title}}</p>
                                            <span>{{item.extend?item.extend.subtitle:''}}<span style="margin-left: 30px;"></span>{{item.extend?item.extend.tag:''}}</span>
                                        </div>
                                        <img src="../../assets/images/bfgy.png" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

</template>


<script>
    import Swiper from 'swiper';
    import 'swiper/css/swiper.min.css';
    import 'swiper/js/swiper.min.js';

    const axios = require("axios").default;
    export default {
        //在这里调用ajax请求方法
        created() {
            this.getstumessages();
        },
        props: {
            major: {type: String, required: true}
        },
        data() {
            return {
                stumessage: '',
                evaluateImg_con: null,
                evaluateText: null
            }
        },
        methods: {
            getstumessages() {
                var that = this;
                axios.get("/api/block/getItemsByName?name=学生感言&major=").then(res => {
                    this.stumessage = res.data.data; //学生感言列表数据
                    this.$nextTick(() => {
                        // 学生感言
                        that.evaluateImg_con && that.evaluateImg_con.destroy();
                        that.evaluateImg_con = new Swiper('.evaluateImg_con', {
                            slidesPerView: 1,
                            slidesPerGroup: 1,
                            freeMode: true,
                            watchSlidesVisibility: true,
                            watchSlidesProgress: true,
                            observer: true,//修改swiper自己或子元素时，自动初始化swiper
                            observeParents: false,//修改swiper的父元素时，自动初始化swiper
                            onSlideChangeEnd: function (swiper) {
                                swiper.update();
                                that.evaluateImg_con.startAutoplay();
                                that.evaluateImg_con.reLoop();
                            }
                        });

                        that.evaluateText && that.evaluateText.destroy();
                        that.evaluateText = new Swiper('.evaluate_ringt', {
                            spaceBetween: 10,
                            observer: true,//修改swiper自己或子元素时，自动初始化swiper
                            observeParents: false,//修改swiper的父元素时，自动初始化swiper
                            navigation: {
                                nextEl: '.evaluateCon-prev',
                                prevEl: '.evaluateCon-next',
                            },
                            thumbs: {
                                swiper: that.evaluateImg_con
                            },
                            onSlideChangeEnd: function (swiper) {
                                swiper.update();
                                that.evaluateText.startAutoplay();
                                that.evaluateText.reLoop();
                            }
                        });
                    });
                });
            },
        },
    }

</script>

<style lang="scss" scoped>
    .flex_style {
        display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
        display: -ms-flexbox; /* TWEENER - IE 10 */
        display: -webkit-flex; /* NEW - Chrome */
        display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
    }

    .direction_row {
        -webkit-flex-direction: row;
        -moz-flex-direction: row;
        -ms-flex-direction: row;
        -o-flex-direction: row;
        flex-direction: row;
    }

    .direction_column {
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        -o-flex-direction: column;
        flex-direction: column;
    }

    .flex_wrap {
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        -o-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .flex_nowrap {
        -webkit-flex-wrap: nowrap;
        -moz-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        -o-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }

    .align_flex-start {
        -webkit-align-items: flex-start;
        -moz-align-items: flex-start;
        -ms-align-items: flex-start;
        -o-align-items: flex-start;
        align-items: flex-start;
    }

    .align_center {
        -webkit-align-items: center;
        -moz-align-items: center;
        -ms-align-items: center;
        -o-align-items: center;
        align-items: center;
    }

    .align_end {
        -webkit-align-items: flex-end;
        -moz-align-items: flex-end;
        -ms-align-items: flex-end;
        -o-align-items: flex-end;
        align-items: flex-end;
    }

    .justify_start {
        -webkit-justify-content: flex-start;
        -moz-justify-content: flex-start;
        -ms-justify-content: flex-start;
        -o-justify-content: flex-start;
        justify-content: flex-start;
    }

    .justify_end {
        -webkit-justify-content: flex-end;
        -moz-justify-content: flex-end;
        -ms-justify-content: flex-end;
        -o-justify-content: flex-end;
        justify-content: flex-end;
    }

    .justify_center {
        -webkit-justify-content: center;
        -moz-justify-content: center;
        -ms-justify-content: center;
        -o-justify-content: center;
        justify-content: center;
    }

    .justify_around {
        -webkit-justify-content: space-around;
        -moz-justify-content: space-around;
        -ms-justify-content: space-around;
        -o-justify-content: space-around;
        justify-content: space-around;
    }

    .justify_between {
        -webkit-justify-content: space-between;
        -moz-justify-content: space-between;
        -ms-justify-content: space-between;
        -o-justify-content: space-between;
        justify-content: space-between;
    }

    .text_left {
        text-align: left;
    }

    .con_title {
        margin: 1.041vw 0 2.604vw;
    }

    .con_title img {
        width: 3.541vw;
        height: .729vw;
        margin-top: .52vw;
    }

    .con_title h4 {
        font-size: 2.5rem;
        font-weight: 800;
        margin: 1.041vw 0;
    }

    .con_title span {
        font-size: .8125rem;
        font-weight: bold;
        color: rgba(102, 102, 102, 1);

    }

    .con_title p {
        width: 35.41vw;
        font-size: 1.375rem;
        font-weight: 500;
        color: rgba(145, 145, 145, 1);
        line-height: 1.822vw;
    }

    .evaluate {
        width: 100%;
        height: 46.354vw;
        background: url('~@/assets/images/gyxzbj.png') no-repeat left center;
        background-size: auto 100%;
        -moz-background-size: auto 100%;
    }

    .evaluateBox {
        width: 62.5vw;
        margin: 0 auto;
    }

    .evaluateImg_box {
        width: 23.6vw;
        height: 100%;
        background: url("../../assets/images/gyzjbj.png") no-repeat;
        background-size: auto 100%;
        position: relative;
    }

    .evaluateImg_con {
        width: 8.125vw;
        height: 8.125vw;
        position: absolute !important;
        right: 2.9vw;
        top: 8.9vw;
        border-radius: 50%;
    }

    .img-con-slide, .img-con-slide img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    .img-con-slide img {
        object-fit: cover;
    }

    .evaluateCon {
        width: 38.854vw;
        position: relative;
    }

    .evaluate_ringt {
        widows: 100%;
        position: relative;
        overflow: hidden;
    }

    .evaluateText {
        width: 100%;
        height: 14.895vw;
        padding: 1vw 3.125vw 1.833vw 0;
        box-sizing: border-box;
        background: url('~@/assets/images/gytextbj.png') no-repeat center;
        background-size: 100% 100%;
        -moz-background-size: 100% 100%;
        position: relative;
    }

    .evaluateText div {
        width: 28.854vw;
    }

    .evaluateText_top p {
        height: 6vw;
        overflow: auto;
        font-size: 1.25rem;
        line-height: 1.5625vw;
        text-align: justify;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: rgba(127, 127, 127, 1);
    }

    .evaluateText_top p span {
        font-size: 1.25rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: rgba(127, 127, 127, 1);
    }

    .evaluateText_top p::-webkit-scrollbar {
        width: 5px;
        height: 110px;
        background-color: #F5F5F5;
    }

    /*定义滚动条轨道 内阴影+圆角*/
    .evaluateText_top p::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #F5F5F5;
    }

    /*定义滑块 内阴影+圆角*/
    .evaluateText_top p::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
        background-color: #bdbdbd;
    }

    /*滑块效果*/
    .evaluateText_top p::-webkit-scrollbar-thumb:hover {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: rgba(0, 0, 0, 0.4);
    }


    .evaluateText_bottom {
        text-align: justify;
    }

    .evaluateText_bottom p {
        font-size: 1.25rem;
        font-family: MicrosoftYaHei;
        font-weight: bold;
        /*text-align: justify;*/
    }

    .evaluateText_bottom span {
        font-size: 1rem;
        font-family: MicrosoftYaHei;
        font-weight: bold;
        color: rgba(220, 49, 110, 1);
        /*text-align: justify;*/
    }

    .evaluateText_bottom img {
        width: 3.489vw;
        height: 3.489vw;
    }

    .arrowBox {
        width: 4.166vw !important;
        position: absolute;
        right: 4vw;
        bottom: .5vw;
    }

    .arrowBox img {
        width: 2.604vw;
        height: 2.604vw;
        margin-left: .52vw;
        outline: none;
    }
</style>
